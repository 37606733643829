import Vue from 'vue'
import VueRouter from 'vue-router'
// import { createRouter, createWebHashHistory,VueRouter } from "vue-router";

//调用 Vue.use()函数，把VueRouter 安装为Vue的插件
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
};
//创建路由的实例对象
const routes = [{
	path: "/",
	name: "pcHome",
	component: () =>import("@/pages/pc/index.vue"),
},
{
	path: '/pc',
	name: 'pcHome',
	component: () =>import("@/pages/pc/index.vue")
},
{
	path: '/h5',
	name: 'H5Home',
	component: () =>import("@/pages/h5/index.vue")
}
]
const router = new VueRouter({
	mode: 'hash',
	routes
});
// const router = createRouter({
//     // history: createWebHashHistory(),
//     routes,
// });
//向外共享路由的实例对象
export default router