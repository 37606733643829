<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>
<script>
    export default {
        name: 'App',
        components: {

        },
        created() {
            const _isMobile = () => {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            }
            if (_isMobile()) {
                // alert("手机端");
                this.$router.push('/h5');
            } else {
                //alert("pc端");
                this.$router.push('/');
            }
        },
        mounted() {

        }
    }
</script>

<style>
    html,
    body {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    
    #app {
        width: 100%;
        height: 100%;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        padding: 0;
        margin: 0;
        /* margin-top: 60px; */
    }
    
     ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    
     ::-webkit-scrollbar-track {
        border-radius: 8px;
    }
    
     ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #ddd;
    }
    
     ::-webkit-scrollbar-thumb:hover {
        background: #ccc;
    }
    
     ::-webkit-scrollbar-thumb:active {
        background: #999;
    }
</style>