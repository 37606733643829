import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/rem.js'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),

}).$mount('#app')